import React from 'react';
import { Button, notification } from 'antd';
import { axius, images } from 'helpers';
import { LoadingMin } from './Loading';


interface Props {
    name: string;
    data?: any;
    mapData: (e: any) => void;
    fetchData?: {
        query: any;
        endpoint: string;
    };
}
const ExportData = ({ name, data, mapData, fetchData }: Props) => {

    const [loading, setLoading] = React.useState(false);

    const initiate = () => {
        if (fetchData) {
            setLoading(true);
            axius.get(fetchData.endpoint, { ...fetchData.query, action: 'download' }).then(res => {
                if (res.status === 200) {
                    if (res.data.queue) {
                        notification.success(res);
                    } else {
                        downloadData(mapData(res.data.data));
                    }
                } else {
                    notification.error(res);
                }
                setLoading(false);
            });
        } else {
            downloadData(mapData(data));
        }
    }

    const objectToCsv = function (data: any) {
        const csvRows = [];
        const headers = Object.keys(data[0] || []);
        csvRows.push(Object.keys(data[0]).map(val => {
            return `"${val}"`;
        }).join(','));
        for (const row of data) {
            const values = headers.map(header => {
                const val = row[header];
                return `"${val}"`;
            });
            csvRows.push(values.join(','));
        }
        return csvRows.join('\n');
    };

    const downloadData = (data: any) => {
        const csv = objectToCsv(data);
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', `${name}.csv`);
        a.click();
    }

    return (
        <React.Fragment>
            <Button type="default" size="large" disabled={loading} onClick={initiate}>
                {loading ? <LoadingMin /> : <div className="flex"><img src={images.Download} alt="Download" /> &nbsp;Export</div>}
            </Button>
        </React.Fragment>
    );

};

export default ExportData;