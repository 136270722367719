import React from 'react';
import { Button, DatePicker, Drawer, Form } from 'antd';
import { axius, helpers, images, types } from 'helpers';
import dayjs, { Dayjs } from 'dayjs';

interface Props {
    row: types.Business;
    open: boolean;

    onClose: () => void;
}
const FormBusinessWallet = ({ open, row, onClose }: Props) => {

    const [form] = Form.useForm();

    const [data, setData] = React.useState<null | { data: Record<types.Currencies, number> }>();
    const [loading, setLoading] = React.useState(false);

    const submit = async (v: { date: Dayjs[] }) => {
        setData(null);
        setLoading(true);
        axius.get(`business/${row.id}/balance`, { date: `${dayjs(v.date[0]).format('YYYY-MM-DD')},${dayjs(v.date[1]).format('YYYY-MM-DD')}` }).then(res => {
            setData(res.data);
            setLoading(false);
            console.log(res.data);
        });
    }

    const close = () => {
        form.resetFields();
        onClose();
        setData(null);
    }

    return (
        <React.Fragment>
            <Drawer
                open={open}
                title={null}
                width={380}
                closable={true}
                onClose={close}
                maskClosable={false}
            >
                <Form form={form} layout="vertical" onFinish={submit}>
                    <div className="flex justify-between items-center mb-8">
                        <div className="text-xl font-semibold">Merchant Balance</div>
                        <div
                            className="bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-200 p-3 ml-2"
                            onClick={close}
                        >
                            <img src={images.Close} alt="Close" />
                        </div>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                        <div className="col-span-2">
                            <Form.Item name="date" label="Date range" initialValue={[dayjs('2023-01-24'), dayjs()]} rules={helpers.requiredInput}>
                                <DatePicker.RangePicker className="w-full" format="DD MMM, YYYY" disabled={loading} />
                            </Form.Item>
                        </div>
                        <div className="col-span-2 mt-4s">
                            <Button type="primary" size="large" htmlType="submit" block loading={loading}>
                                Generate
                            </Button>
                        </div>
                    </div>

                    {data && data?.data && (
                        <div className="bg-gray-50 px-4 rounded-lg shadow pb-4 mt-8">
                            {Object.keys(data?.data).map((currency) => (
                                <div key={currency} className="flex border-b py-2">
                                    <div className="w-2/6 font-semibold">{currency}</div>
                                    <div className="w-4/6">{helpers.format.currency(data.data[currency as types.Currencies], currency)}</div>
                                </div>
                            ))}
                        </div>
                    )}
                </Form>
            </Drawer>
        </React.Fragment>
    );
};

export default FormBusinessWallet;