import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loading, Tabs } from 'components';
import { helpers } from 'helpers';

const Logs = React.lazy(() => import('./Logs'));
const Messages = React.lazy(() => import('./Messages'));
const SuccessRate = React.lazy(() => import('./SuccessRate'));
// const StatusMismatch = React.lazy(() => import('./StatusMismatch'));

const Settings = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const items = [
        { key: 'logs', label: 'Activities Log', children: <Logs />, permission: '', },
        { key: 'messages', label: 'Messages', children: <Messages />, permission: '', },
        { key: 'success-rate', label: 'Success Rate', children: <SuccessRate />, permission: '', },
        // { key: 'status-mismatch', label: 'Status Mismatch', children: <StatusMismatch />, permission: '', },
    ].filter(item => !item.permission || helpers.hasPermission(item.permission));

    const current = location.pathname.split('/')[2] || items[2].key;

    return (
        <React.Fragment>
            <React.Suspense fallback={<Loading spinning={true} />}>
                <Tabs
                    type="card"
                    value={current}
                    items={items}
                    onChange={(e) => navigate(e)}
                />
            </React.Suspense>
        </React.Fragment>
    );
}

export default Settings;